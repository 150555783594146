<main class="app app-site">
  <!--[if lt IE 10]>
      <div class="page-message" role="alert">You are using an <strong>outdated</strong> browser. Please <a class="alert-link" href="http://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</div>
      <![endif]-->
  <!-- site header -->
  <!-- .navbar -->
  <nav class="navbar navbar-expand-lg navbar-light py-4" data-aos="fade-in">
    <!-- .container -->
    <div class="container">
      <!-- .hamburger -->
      <button class="hamburger hamburger-squeeze hamburger-light d-flex d-lg-none" type="button" data-toggle="collapse"
        data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false"
        aria-label="Toggle navigation"><span class="hamburger-box"><span class="hamburger-inner"></span></span></button>
      <!-- /.hamburger -->
      <!-- .navbar-brand -->
      <a class="navbar-brand ml-auto mr-0" href="#">

        <img class="rounded" src="../../assets/images/logo/logo.svg" alt="" height="45">

      </a> <!-- /.navbar-brand -->
      <a class="navbar-btn btn btn-subtle-primary ml-auto order-lg-2" [routerLink]="['/login']">Login</a>
      <div class="navbar-collapse collapse" id="navbarTogglerDemo01">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item mr-lg-3 active">
            <a class="nav-link py-2" href="#">Home</a>
          </li>
          <li class="nav-item mr-lg-3">
            <a class="nav-link py-2" href="#">Features</a>
          </li>
          <li class="nav-item mr-lg-3">
            <a class="nav-link py-2" href="#">Pricing</a>
          </li>
          <li class="nav-item mr-lg-3">
            <a class="nav-link py-2" href="#">Contact</a>
          </li>
        </ul>
      </div><!-- /.navbar-collapse -->
    </div><!-- /.container -->
  </nav><!-- /.navbar -->
  <section class="py-5">
    <div class="container position-relative">
      <div class="row align-items-center">
        <div class="col-12 col-md-5 order-md-2" data-aos="fade-left">
          <div class="card">
            <div class="card-body">
              <form class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-row">
                  <div class="col-md-6 mb-3">
                    <label>First name </label> <input type="text" class="form-control" placeholder="First name"
                      required="" formControlName="first_name"
                      [ngClass]="{ 'is-invalid': submitted && f['first_name'].errors }">
                    <div *ngIf="submitted && f['first_name'].errors" class="invalid-feedback">
                      <div *ngIf="f['first_name'].errors['required']">First Name required</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label>Last name </label> <input type="text" class="form-control" placeholder="Last name"
                      required="" formControlName="last_name"
                      [ngClass]="{ 'is-invalid': submitted && f['last_name'].errors }">
                    <div *ngIf="submitted && f['last_name'].errors" class="invalid-feedback">
                      <div *ngIf="f['last_name'].errors['required']">Last Name required</div>
                    </div>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="validationTooltipUsername">Email </label> <input type="text" class="form-control"
                      placeholder="email" aria-describedby="inputGroupPrepend" required="" formControlName="email"
                      [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">
                    <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                      <div *ngIf="f['email'].errors['required']">Email is required</div>
                      <div *ngIf="f['email'].errors['email']">Email is invalid</div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="validationTooltipCountry">Country </label> <select class="custom-select d-block w-100"
                      formControlName="countryId" required="">
                      <option value=""> Country </option>
                      <option [value]="countries._id" *ngFor="let countries of countryList">
                        {{countries.countryCode}} ({{countries.mobileCode}})
                      </option>
                    </select>
                    <div *ngIf="submitted && form.get('countryId')?.invalid" class="text-danger">
                      Country required
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label>Phone Number </label> <input type="text" class="form-control" placeholder="Phone Number"
                      required="" formControlName="contact"
                      [ngClass]="{ 'is-invalid': submitted && f['contact'].errors }">
                    <div *ngIf="submitted && f['contact'].errors" class="invalid-feedback">
                      <div *ngIf="f['contact'].errors['required']">Contact is
                        required</div>
                      <div *ngIf="f['contact'].errors['pattern']">
                        Invalid Contact number
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-actions" *ngIf="!isLoading">
                  <button class="btn btn-primary" type="submit">Submit</button>
                </div>
                <div *ngIf="isLoading">
                  <button class="btn btn-lg btn-primary btn-block" type="button" disabled><span
                      class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Please wait...</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 order-md-1" data-aos="fade-in">
          <div class="col-fix pl-xl-3 ml-auto text-center text-sm-left">
            <h1 class="display-4 enable-responsive-font-size mb-4"> Expand Your Reach, <strong>Educate
                Globally</strong>.
            </h1>
            <p class="lead text-muted mb-5"> Choose from multiple languages and seamlessly link your educational videos
              to our platform. Engage with a targeted audience seeking specialized knowledge and skill-building
              resources. Enhance your brand visibility and connect with users eager to expand their learning horizons
            </p><a href="index.html" class="btn btn-lg btn-primary d-block d-sm-inline-block mr-sm-2 my-3"
              data-aos="zoom-in" data-aos-delay="200">Let's Try <i class="fa fa-angle-right ml-2"></i></a> <a
              href="/docs" class="btn btn-lg btn-subtle-primary d-block d-sm-inline-block my-3" target="_blank"
              data-aos="zoom-in" data-aos-delay="300">Documentation</a>
          </div>
        </div><!-- /.col-md-6 -->
      </div><!-- /.row -->
    </div>
  </section>

  <!-- divider -->
  <section class="position-relative">
    <!-- wave2.svg -->
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="192" viewbox="0 0 1440 240" preserveaspectratio="none">
      <path class="fill-light" fill-rule="evenodd"
        d="M0 240V0c19.985 5.919 41.14 11.008 63.964 14.89 40.293 6.855 82.585 9.106 125.566 9.106 74.151 0 150.382-6.697 222.166-8.012 13.766-.252 27.51-.39 41.21-.39 99.76 0 197.087 7.326 282.907 31.263C827.843 72.527 860.3 117.25 906.926 157.2c43.505 37.277 115.38 51.186 208.485 53.076 7.584.153 15.156.224 22.714.224 40.887 0 81.402-2.062 121.914-4.125 40.512-2.062 81.027-4.125 121.902-4.125 1.01 0 2.019.002 3.03.004 16.208.042 34.959.792 55.029 2.234V240H0z">
      </path>
    </svg>
  </section><!-- /divider -->

  <section class="py-5 position-relative bg-light">
    <!-- .sticker -->
    <div class="sticker">
      <div class="sticker-item sticker-bottom-left w-100">
        <!-- wave1.svg -->
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="120"
          viewbox="0 0 1440 240" preserveaspectratio="none">
          <path class="fill-black" fill-rule="evenodd"
            d="M1070.39 25.041c107.898 11.22 244.461 20.779 369.477 51.164L1440 240H0L.133 72.135C350.236-17.816 721.61-11.228 1070.391 25.04z">
          </path>
        </svg>
      </div>
    </div><!-- /.sticker -->
    <!-- .container -->
    <div class="container">
      <!-- .card -->
      <div class="card bg-success text-white position-relative overflow-hidden shadow rounded-lg p-4 mb-0"
        data-aos="fade-up">
        <!-- .sticker -->
        <div class="sticker">
          <div class="sticker-item sticker-middle-left">
            <img class="flip-y" src="assets/images/decoration/bubble4.svg" alt="">
          </div>
        </div><!-- /.sticker -->
        <!-- .card-body -->
        <div class="card-body d-md-flex justify-content-between align-items-center text-center position-relative">
          <h3 class="font-weight-normal mb-3 mb-md-0 mr-md-3"> Got a question about Referredx? </h3><a
            class="btn btn-lg btn-primary shadow" href="https://referredx.com/contact" target="_blank">Contact us <i
              class="fa fa-angle-right ml-2"></i></a>
        </div><!-- /.card-body -->
      </div><!-- /.card -->
    </div><!-- /.container -->
  </section><!-- /call to action -->
  <!-- footer -->
  <section class="py-5 bg-black text-white">
    <!-- .container -->
    <div class="container">
      <!-- .row -->
      <div class="row">
        <!-- .col -->
        <div class="col-12 col-md-4 col-lg-3">
          <!-- Brand -->
          <svg class="mb-3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="28"
            viewbox="0 0 351 100">
            <defs>
              <path id="a"
                d="M156.538 45.644v1.04a6.347 6.347 0 0 1-1.847 3.98L127.708 77.67a6.338 6.338 0 0 1-3.862 1.839h-1.272a6.34 6.34 0 0 1-3.862-1.839L91.728 50.664a6.353 6.353 0 0 1 0-9l9.11-9.117-2.136-2.138a3.171 3.171 0 0 0-4.498 0L80.711 43.913a3.177 3.177 0 0 0-.043 4.453l-.002.003.048.047 24.733 24.754-4.497 4.5a6.339 6.339 0 0 1-3.863 1.84h-1.27a6.337 6.337 0 0 1-3.863-1.84L64.971 50.665a6.353 6.353 0 0 1 0-9l26.983-27.008a6.336 6.336 0 0 1 4.498-1.869c1.626 0 3.252.622 4.498 1.87l26.986 27.006a6.353 6.353 0 0 1 0 9l-9.11 9.117 2.136 2.138a3.171 3.171 0 0 0 4.498 0l13.49-13.504a3.177 3.177 0 0 0 .046-4.453l.002-.002-.047-.048-24.737-24.754 4.498-4.5a6.344 6.344 0 0 1 8.996 0l26.983 27.006a6.347 6.347 0 0 1 1.847 3.98zm-46.707-4.095l-2.362 2.364a3.178 3.178 0 0 0 0 4.501l2.362 2.364 2.361-2.364a3.178 3.178 0 0 0 0-4.501l-2.361-2.364z">
              </path>
            </defs>
            <g fill="none" fill-rule="evenodd">
              <path fill="currentColor" fill-rule="nonzero"
                d="M39.252 80.385c-13.817 0-21.06-8.915-21.06-22.955V13.862H.81V.936h33.762V58.1c0 6.797 4.346 9.026 9.026 9.026 2.563 0 5.237-.446 8.58-1.783l3.677 12.034c-5.794 1.894-9.694 3.009-16.603 3.009zM164.213 99.55V23.78h13.372l1.225 5.571h.335c4.457-4.011 10.585-6.908 16.491-6.908 13.817 0 22.174 11.031 22.174 28.08 0 18.943-11.588 29.863-23.957 29.863-4.903 0-9.694-2.117-13.594-6.017h-.446l.78 9.025V99.55h-16.38zm25.852-32.537c6.128 0 10.92-4.903 10.92-16.268 0-9.917-3.232-14.932-10.14-14.932-3.566 0-6.797 1.56-10.252 5.126v22.397c3.12 2.674 6.686 3.677 9.472 3.677zm69.643 13.372c-17.272 0-30.643-10.586-30.643-28.972 0-18.163 13.928-28.971 28.748-28.971 17.049 0 26.075 11.477 26.075 26.52 0 3.008-.558 6.017-.78 7.354h-37.663c1.56 8.023 7.465 11.589 16.491 11.589 5.014 0 9.36-1.337 14.263-3.9l5.46 9.917c-6.351 4.011-14.597 6.463-21.951 6.463zm-1.338-45.463c-6.462 0-11.031 3.454-12.702 10.363h23.622c-.78-6.797-4.568-10.363-10.92-10.363zm44.238 44.126V23.779h13.371l1.337 12.034h.334c5.46-9.025 13.595-13.371 22.398-13.371 4.902 0 7.465.78 10.697 2.228l-3.343 13.706c-3.454-1.003-5.683-1.56-9.806-1.56-6.797 0-13.928 3.566-18.608 13.483v28.749h-16.38z">
              </path>
              <use class="fill-primary" xlink:href="#a"></use>
            </g>
          </svg> <!-- Description -->
          <p class="text-muted mb-2"> The theme for your project. </p>
          <address class="text-muted">
            <abbr title="US phone code">+1</abbr> (234) 567-8910
          </address><!-- Social -->
          <ul class="list-inline mb-5 mb-md-0">
            <li class="list-inline-item mr-3" data-aos="fade-in" data-aos-delay="0">
              <a href="#" class="text-muted text-decoration-none" title="twitter"><img class="grayscale"
                  src="assets/images/avatars/twitter.svg" alt="" width="24"></a>
            </li>
            <li class="list-inline-item mr-3" data-aos="fade-in" data-aos-delay="100">
              <a href="#" class="text-muted text-decoration-none" title="instagram"><img class="grayscale"
                  src="assets/images/avatars/instagram.svg" alt="" width="24"></a>
            </li>
            <li class="list-inline-item mr-3" data-aos="fade-in" data-aos-delay="200">
              <a href="#" class="text-muted text-decoration-none" title="dribbble"><img class="grayscale"
                  src="assets/images/avatars/dribbble.svg" alt="" width="24"></a>
            </li>
            <li class="list-inline-item mr-3" data-aos="fade-in" data-aos-delay="300">
              <a href="#" class="text-muted text-decoration-none" title="medium"><img class="grayscale"
                  src="assets/images/avatars/medium.svg" alt="" width="24"></a>
            </li>
          </ul>
        </div><!-- /.col -->
        <!-- .col -->
        <div class="col-6 col-md-4 col-lg-2 mb-3 mb-md-0">
          <!-- Heading -->
          <h6 class="mb-4"> Company </h6><!-- links -->
          <ul class="list-unstyled">
            <li class="mb-3">
              <a href="#" class="text-muted">About Us</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">Blog</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">Knowledge Base</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">Press</a>
            </li>
          </ul>
        </div><!-- /.col -->
        <!-- .col -->
        <div class="col-6 col-md-4 col-lg-2 mb-3 mb-md-0">
          <!-- Heading -->
          <h6 class="mb-4"> Product </h6><!-- links -->
          <ul class="list-unstyled">
            <li class="mb-3">
              <a href="#" class="text-muted">SaaS</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">Project Management</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">eCommerce</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">CRM</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">CMS</a>
            </li>
          </ul>
        </div><!-- /.col -->
        <!-- .col -->
        <div class="col-6 col-md-4 col-lg-2 mb-3 mb-md-0">
          <!-- Heading -->
          <h6 class="mb-4"> Help </h6><!-- links -->
          <ul class="list-unstyled">
            <li class="mb-3">
              <a href="#" class="text-muted">Help Center</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">Documentation</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">Technical Support</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">FAQ</a>
            </li>
          </ul>
        </div><!-- /.col -->
        <!-- .col -->
        <div class="col-6 col-md-4 col-lg-2 mb-3 mb-md-0">
          <!-- Heading -->
          <h6 class="mb-4"> Legal </h6><!-- links -->
          <ul class="list-unstyled">
            <li class="mb-3">
              <a href="#" class="text-muted">Privacy Policy</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">Terms of Service</a>
            </li>
            <li class="mb-3">
              <a href="#" class="text-muted">Cookies Policy</a>
            </li>
          </ul>
        </div><!-- /.col -->
      </div><!-- /.row -->
      <p class="text-muted text-center mt-5"> © 2019 Looper, Inc. All rights reserved. </p>
    </div><!-- /.container -->
  </section><!-- /footer -->
</main><!-- /.app -->