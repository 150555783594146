<main class="auth">
  <header id="auth-header" class="auth-header" style="background-image: url(assets/images/illustration/img-1.png);">
    <h1>
      <a href="javascript:void(0)">
        <img class="rounded" src="../../assets/images/logo/asset.svg" alt="" height="55">
      </a>
      <span class="sr-only">Sign Up</span>
    </h1>
    <p> Already have an account? please <a href="javascript:void(0)">Sign In</a>
    </p>
  </header>
  <form class=" auth-form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <div class="form-label-group">
        <input type="password" id="currentPassword" class="form-control" placeholder="Current Password" required=""
          autofocus="" autocomplete="current-password" formControlName="currentPassword"
          [ngClass]="{ 'is-invalid': submitted && f['currentPassword'].errors }" autocomplete="currentPassword">
        <label for="inputEmail">Current
          Password</label>
        <div *ngIf="submitted && f['currentPassword'].errors" class="invalid-feedback">
          <div *ngIf="f['currentPassword'].errors['required']">Current password is required</div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-label-group">
        <input type="password" id="newPassword" class="form-control" placeholder="New Password" required=""
          autocomplete="new-password" formControlName="newPassword"
          [ngClass]="{ 'is-invalid': submitted && f['newPassword'].errors }" autocomplete="newPassword"> <label
          for="inputUser">New Password</label>
        <div *ngIf="submitted && f['newPassword'].errors" class="invalid-feedback">
          <div *ngIf="f['newPassword'].errors['required']">Current password is required</div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="form-label-group">
        <input type="password" id="confirmPassword" class="form-control" placeholder="Confirm Password" required=""
          autocomplete="confirm-password" formControlName="confirmPassword"
          [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }" autocomplete="confirmPassword">
        <label for="inputPassword">Confirm Password</label>
        <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
          <div *ngIf="f['confirmPassword'].errors['required']">Current password is required</div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn-lg btn-primary btn-block" type="submit">Change Password</button>
    </div>
    <div class="form-group text-center">
      <div class="custom-control custom-control-inline custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="newsletter"> <label class="custom-control-label"
          for="newsletter">Sign me up for the newsletter</label>
      </div>
    </div>
    <p class="text-center text-muted mb-0"> By creating an account you agree to the <a href="#">Terms of Use</a> and
      <a href="#">Privacy Policy</a>.
    </p>
  </form>
  <footer class="auth-footer"> © 2018 All Rights Reserved. </footer>
</main>